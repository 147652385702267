/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"

const Input = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:100%;
`

const InputRadio = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:40px;
`

const SubmitButton = styled.button`
  border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`   

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`

const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff,#efefefdb);
  padding:10px;
  margin-top:40px;
`

const reservations = () => {

  useEffect(() => {
        window.scrollTo(0,0)
    })

const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  reservation1: file(relativePath: {eq: "ereservations/reservations11.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  reservation2: file(relativePath: {eq: "ereservations/reservations12.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image1: file(relativePath: {eq: "ereservations/android.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image1: file(relativePath: {eq: "ereservations/android.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image2: file(relativePath: {eq: "ereservations/applestore.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image3: file(relativePath: {eq: "ereservations/guidos_logo.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image4: file(relativePath: {eq: "ereservations/snaptown.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)  

  const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/33fc9681-937d-40a3-9776-38ff436db3db",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };

     
    

        return (
       
   <div id="outer-container">
   <SEO/>
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <div id="page-wrap">
     
        <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
        <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
        <h1  sx={{
          color: "heading",
          m: 1,
          mt:80,
          mb:40
        }}>Electronic Reservation Book</h1>

      

        <Img sx={{m:'0 auto'}}
            fluid={data.reservation1.childImageSharp.fluid}
            alt="reservations table"
            style={{ width:`100%`}} 
        />
       
        <div>
        <h2>Solution</h2>
         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
      Allowing clients the ability to place reservations increases every business' ability to succeed & plan for success.</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
Many restaurateurs have elected to opt for OpenTable or Yelp as the mechanism for managing reservations only to find they’re delivering loss leaders with zero opportunity for recoup & a scavenged customer-base. 
</p>
<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
Our management suite includes an In-location digital reservation ledger offering an instant view of current & upcoming reservations. 
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
The solution works on all mobile, tablet & desktop platforms.
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
Programs start at $99.00 per month & includes hosting, support and maintenance services
</p>


      </div>

      <div>
        <h2>Useful to</h2>
        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
       Restaurants, salons/spas, hotels, appointment-based businesses
        </p>

        <Img
            fluid={data.reservation2.childImageSharp.fluid}
            alt="reservations table"
            style={{ width:`100%`}} 
        />

      </div>

       <div id="ttm-form" sx={{
        m:"0 auto",
        mt:50,
        width:'100%',
        padding:40,
        background:'#191e2b'}}>
                <h2>Get Connected</h2>
                
                <div>
                    <form name="reservations"  onSubmit={handleOnSubmit}>
                      <Input
                            type="text"
                            placeholder="name"
                            name="name"
                            required
                        />
                        <br/>
                        <Input
                            type="email"
                            placeholder="email"
                            name="email"
                            required
                        />
                        <br />
                        <Input 
                          type="tel"
                          placeholder="phone" 
                          name="phone" 
                        />
                        <br />
                         <Input 
                          type="text"
                          placeholder="company" 
                          name="company" 
                        />
                        <br />
                        <br />
                        <b>Follow up with you?</b>
                        <ul style={{listStyleType:`none`}}>
                          <li>
                                <InputRadio
                                  required
                                    type="radio"
                                    value="Yes"
                                    name="FOLLOWUP"
                                />
                                <label>Yes</label>
                                </li>

                               <li>
                                <InputRadio
                                  required
                                    type="radio"
                                    value="No"
                                    name="FOLLOWUP"
                                /> 
                                <label>No</label>
                                </li>
                        </ul>                                                    
                         <SubmitButton type="submit" disabled={serverState.submitting}> Send </SubmitButton>
                        {serverState.status && (
                        <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                        </p>
                        )}
                    </form>
                </div>
            </div>

          <ImageWrapper>
           <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="Android Logo"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="Apple App Store Logo" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="Guidos LA Restaurant Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="snaptown logo" 
            style={{ width:`100%`}}
          />
        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>
        </div>
      </div>  
    </div>
    
  )
    
}


export default reservations

